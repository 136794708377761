import React from "react";
import { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { service } from "./servicesApi";
import { service1 } from "./servicesApi";
import { service2 } from "./servicesApi";
import { useTranslation } from "react-i18next"
import i18n from "i18next";



function Services() {
  
  const [bull, setBull] = useState(true);
  const [bull1, setBull1] = useState(true);
  const [bull2, setBull2] = useState(true);
  const [bull3, setBull3] = useState(true);
  const { t } = useTranslation();
  const [orderId, setOrderId] = useState('')


const handleindex = (e)=>{
 
  setOrderId(e.target.dataset.id)
  

}

  return (
    <div className="services-cont" id="services">
      <div className="services-title">
        <h2 className="title-h2">{t("services")}</h2>
      </div>
      <div className="services">
        <div className="serBox">
        <div className="box" style={{ display: bull ? "block" : "none" }}>
        <h3>{t("servicesDesign")}</h3>

          <ul className="ul">
            {service.map((item, index) => {
             
              return (
                <li 
                  data-id={index}
                  key={item.id}
                  className="li"
                  onMouseDown={handleindex}
                  onClick={() => {
                    setBull(!bull);
                  }}
                >
                  {item[`title_${i18n.language}`]}
                </li>
              );
            })}
          </ul>
        </div>

        <div className="div-none" style={{ display: bull ? "none" : "block" }}>


          <div className="info-blok">
          <h3>     {service[orderId]?.[`title_${i18n.language}`]} </h3>
                <p className="p">

                  {service[orderId]?.[`description_${i18n.language}`]}
                </p>
          
            <BiChevronLeft
              className={"icons"}
              onClick={() => {
                setBull(!bull);
              }}
            />
          </div>

        </div>
        <div className="box-1 box" style={{ display: bull1 ? "block" : "none" }}>
        <h3>{t("servicesDesign1")}</h3>
          <ul className="ul">


          {service1.map((item, index) => {
              
              return (
                <li 
                  data-id={index}
                  key={item.id}
                  className="li"
                  onMouseDown={handleindex}
                  onClick={() => {
                    setBull1(!bull1); }}
                >
                    {item[`title_${i18n.language}`]}
                </li>
              );
            })}
          
          </ul>
        </div>

        <div className="div-none" style={{ display: bull1 ? "none" : "block" }}>
          <div className="info-blok">
           <h3>     {service1[orderId]?.[`title_${i18n.language}`]} </h3>
          <p className="p">

            {service1[orderId]?.[`description_${i18n.language}`]}
            </p>



            <BiChevronLeft
              className={"icons"}
              onClick={() => {
                setBull1(!bull1);
              }}
            />
          </div>

        </div>

        <div className="box-2 box" style={{ display: bull2 ? "block" : "none" }}>
          <h3>{t("servicesDesign2")}</h3>
          <ul className="ul">

          {service2.map((item, index) => {
              
              return (
                <li 
                  data-id={index}
                  key={item.id}
                  className="li"
                  onMouseDown={handleindex}
                  onClick={() => {
                    setBull2(!bull2); }}
                >
                   {item[`title_${i18n.language}`]}
                </li>
              );
            })}
           
          </ul>
        </div>
        <div className="div-none" style={{ display: bull2 ? "none" : "block" }}>


          <div className="info-blok">

          <h3>     {service2[orderId]?.[`title_${i18n.language}`]} </h3>
            
            <p className="p">
            {service2[orderId]?.[`description_${i18n.language}`]}
            </p>

            <BiChevronLeft
              className={"icons"}
              onClick={() => {
                setBull2(!bull2);
              }}
            />
          </div>

        </div>

        <div className="servic-top-line"></div>
        <div className="servic-left-line"></div>
      </div>
      </div>
    </div>
  );
}

export default Services;
