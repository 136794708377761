import React, { useState } from "react";
import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";
import {useTranslation} from "react-i18next"
import i18n from "i18next";

function MyAccordion({ question, answer }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="main-heading" onClick={() => setShow(!show)}>
        <h3>{question[`${i18n.language}`]}</h3>
        <p>
          {show ? <BsArrowUpCircle /> : <BsArrowDownCircle />}
        </p>
      </div>
      {show && <p className="main-heading-text">{answer[`${i18n.language}`]}</p>}
    </>
  );
}

export default MyAccordion;
