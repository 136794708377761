import React from "react";
import { useTranslation } from "react-i18next";

function Design() {
  const { t } = useTranslation();

  return (
    <div className="design_main">
      <div className="elements">
        <div className="half-circle">
          <img src="/images/left.svg" alt="Նախագիծ Վեբ-Կայքերի-Պատրաստում"></img>
        </div>
        <div className="projects">
          <div className="round">
            <div className="round-1">
              <div className="round-2">
                <div className="round-3">
                  <p className="nine">50+</p>
                </div>{" "}
              </div>
            </div>
          </div>
          <h2 className="design-h2">{t("project")}</h2>
        </div>
        <div className="clients">
          <div className="round">
            <div className="round-1">
              <div className="round-2">
                <div className="round-3">
                  <p className="nine">100+</p>
                </div>
              </div>
            </div>
          </div>
          <h2 className="design-h2">{t("client")}</h2>
        </div>
        <div className="experiance">
          <div className="round">
            <div className="round-1">
              <div className="round-2">
                <div className="round-3">
                  <p className="nine">5+</p>
                </div>
              </div>
            </div>
          </div>
          <h2 className="design-h2">{t("experience")}</h2>
        </div>

        <div className="half-circle-1">
          <img src="/images/right.svg" alt="Նախագիծ Վեբ-Կայքերի-Պատրաստում"></img>
        </div>
      </div>
    </div>
  );
}

export default Design;
