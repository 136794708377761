import React from "react";
// import { useState } from "react";
import { questions } from "./api";
import MyAccordion from "./MyAccordion";
import {useTranslation} from "react-i18next"
import i18n from "i18next";


function Question() {
  const { t } = useTranslation();

  // const [data, setData] = useState(questions);
  return (
    <div className="question-part">
      <div className="ques-text">
        <h2>{t("question")} </h2>
      </div>
      <div className="main_div">
        {questions.map((curElem) => {
          const { id } = curElem;
          return <MyAccordion key={id} {...curElem} />;
        })}
      </div>
    </div>
  );
}

export default Question;
