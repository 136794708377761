import React , {useState} from 'react';

import {BsArrowUpShort} from 'react-icons/bs';
import './ScrollToTop.css';

const  ScrolToTop = () =>{
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const ScrolButton = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
   < div className='scrollTop'>
     <BsArrowUpShort  className='scroll_btn' onClick={ScrolButton} 
     style={{display: visible ? 'inline' : 'none'}} />
    </div>
  );
}
  

    
  export default ScrolToTop;