import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import "./sliderop.css";

// import required modules
import {EffectFade, Autoplay, Pagination, Navigation } from "swiper";
import Opinion from "./Opinion";
// import Opinion1 from "./opinion1";
// import Opinion2 from "./opinion2";


export default function Sliderop() {
  return (
    <>
      <Swiper
        navigation={true}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        effect={'fade'}
        breakpoints={{

       
          300: {
           
          },
          
          600: {
            
            
          },
         1240: {
          
            
          }
        }}
        loop ={true}

        
        modules={[Autoplay, Pagination, Navigation,EffectFade]}
        className="mySwiper-2"



      >
        <SwiperSlide><Opinion/></SwiperSlide>
        {/* <SwiperSlide><Opinion1/></SwiperSlide> */}
        {/* <SwiperSlide><Opinion2/></SwiperSlide> */}
   
        
      
      </Swiper>
    </>
  );
}

        

