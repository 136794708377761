import  i18n  from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./local/en/translationEN.json";
import translationHY from "./local/hy/translationHY.json";
import translationRU from "./local/ru/translationRU.json"



i18n

.use(initReactI18next)
.init({
    resources: {
        hy:{
            translation:translationHY
        },
         
        en:{
            translation:translationEN
        },
        ru:{
            translation:translationRU
        }
    },

    lng:'hy',
    keySeparator:false,

    interolation:{
        escapeValue:false
    }
})

export default i18n;
