export const questions = [
  {
    id: 1,
    question: {hy:"Ինչու՞ դիմել հենց մեզ",
  en:  "Why should you work with WEBSOFT?",
ru:  "Зачем обращаться к нам?",},
answer: {hy:"Մենք բարձրորակ մասնագետներից կազմված թիմ ենք: Մեզ չեն վախեցնում մարտահրավերները աշխատանքներ կատարելիս . հասկանում ենք ձեր բիզնեսի գերնպատակներն ու պահանջները և ըստ այդմ առաջարկում ենք առավել գործնական լուծումներ:Յուրաքանչյուր հաճախորդ մեր կողմից արժանանում է անհատական մոտեցման: Կայքերը ստեղծում ենք բավականին սեղմ ժամկետներում, ապահովում ենք Ձեր պրոֆեսիոնալ ներկայությունը արդի թվային աշխարհում: Նման աշխատաոճի շնորհիվ արժանանում ենք մեր հաճախորդների վստահությանը:",
en: "We are a team of professional challengers, we understand your business objectives, and requirements and come up with the most practical solutions. Each customer deserves individual attention. We create websites within tight deadlines…we ensure a professional presence in the digital world which wins the trust of the customers." ,
ru: "Мы команда профессионалов высокого класса. Мы не боимся трудностей в работе. мы понимаем цели и требования вашего бизнеса и предлагаем наиболее практичные решения соответственно.Каждый клиент получает от нас индивидуальный подход. Мы создаем сайты в достаточно короткие сроки, обеспечиваем ваше профессиональное присутствие в современном цифровом мире. Благодаря такому стилю работы мы завоевываем доверие наших клиентов."}
  },

  {
    id: 2,
    question: {hy:"Ինչպե՞ս ստեղծել նախագծել կայքեր",
    en:  "How to create a website?",
  ru:  "Как создавать дизайнерские сайты?",},
  answer: {hy:"Նախքան կայք պատվիրելը, ընկերությունները պետք է սահմանեն այն բոլոր նպատակները, որոնց ձգտում  են հասնել  վեբ կայքի միջոցով: Հարկն է նաև  սահմանել թիրախային լսարանը և ուրվագծել կայքի բովանդակությունը:",
  en: "Before ordering a website, businesses need to define the objective the website is driven to solve. Specify the target audience and scheme the website content that needs to be created." ,
 ru: "Прежде чем заказывать веб-сайт, компании должны определить все цели, которые они пытаются достичь с помощью веб-сайта. Вам также следует определить целевую аудиторию и наметить содержание сайта."}
  },
  {
    id: 3,
    question:{hy:"Որո՞նք են WebSoft-ի հետ աշխատելու առավելությունները",
    en:  "The benefits of working with Websoft",
  ru:  "Каковы преимущества сотрудничества с ВебСофт?",},
   
    answer: {hy:"Մենք առաջարկում ենք հաճախորդների շուրջօրյա սպասարկման պլատֆորմ, որպեսզի նրանք կարողանան անխափան շփվել մեր մասնագետների հետ և իրականացնել իրենց առցանց նախագծերը:",
    en: "We come up with the best 24/7 tech support for our customers so that they are able to realize their online projects" ,
   ru: "Мы предлагаем платформу круглосуточного обслуживания клиентов, чтобы они могли беспрепятственно взаимодействовать с нашими профессионалами и реализовывать свои онлайн-проекты."}
  },
  {
    id: 4,
    question: {hy:"Ի՞նչ է իրենից ներկայացնում դոմեյնը: Ի՞նչ է վեբ հոստինգը",
    en:  "What is a domain? What is web hosting?",
  ru:  "Что такое домен? Что такое веб-хостинг?",},
    answer: {hy:"Ամեն վեբ կայքին անհրաժեշտ է իր պերսոնալիզացված Դոմեյնը և դոմեյնին կցված Հոսթինգը։ Հոսթինգի շնորհիվ ձեր կայքը կդառնա տեսանելի համացանցում որոնողական կայքերից օգտվող անձանց համար, իսկ Դոմեյնը կայքի անունն է, որով հեշտ է դառնում գտնել ձեր իսկ կայքը։",
 en: "A website needs a customized domain and hosting. Thanks to hosting your website will become visible for the users due to the searching machine. The domain is the name of the website which enables one to find the website" ,
ru: "Каждому веб-сайту необходим собственный персонализированный домен и хостинг, прикрепленный к домену. Благодаря хостингу ваш сайт станет виден людям, использующим поисковые системы в Интернете, а домен — это имя сайта, которое позволит легко найти ваш собственный сайт."}
    

  },
  {
    id: 5,
    question: {hy:"Որքա՞ն ժամանակ է տևում ծրագրային ապահովման մշակումը ավարտելու համար",
    en:  "How long does the software development take? ",
  ru:  "Сколько времени занимает разработка программного обеспечения?",},
    answer: {hy:" Յուրաքանչյուր նախագիծ պահանջում է իրականացման համար անհրաժեշտ ժամանակ: Կարող է տևել մեկ շաբաթ, անգամ  որոշ նախագծերի համար անհրաժեշտ են տարիներ…",
 en: "The time varies and depends on each project. Usually from a week to years…" ,
ru: "Любой проект требует времени для реализации. Это может занять неделю, а некоторые проекты могут занять годы..."}
    

  },
  {
    id: 6,
    question: {hy:"Ի՞նչ տեղեկատվություն է հարկավոր աշխատանքը սկսելու համար",
    en:  "What info do you/we need before we start the work?",
  ru:  "Какая информация мне нужна, чтобы начать?",},
    answer: {hy:"Աշխատանքը սկսելու համար հարկավոր է հաճախորդը ներկայացնի աշխատանքի պահանջները:",
 en: "The clients/customers need to present the project and send a brief description." ,
ru: "Чтобы приступить к работе, клиенту необходимо предоставить требования к работе."}
    

  },
];
