import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./slider.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function Slider() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        loop ={true}

        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper-4"
      >
        <SwiperSlide>
          <img className="img" src="/images/header_img1.webp" alt="web kayqeri patrastum"></img>
          </SwiperSlide>
        <SwiperSlide>
          <img className="img" src="/images/header_img2.webp" alt="web kayqer smm "></img>
          </SwiperSlide>
        <SwiperSlide>
          <img className="img" src="/images/header_img3.webp" alt="SMM WEB APP WeBsoft"></img>
        </SwiperSlide>
        
      
      </Swiper>
    </>
  );
}

        

