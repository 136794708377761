import Slidercoll from "./slidercoll";
import { useTranslation } from "react-i18next";

function Colleagues() {
  const { t } = useTranslation();

  return (
    <div className="collegues-cont">
      <h3 className="h3-coll"> {t("sponser")} </h3>
      <div className="collegues-slider-blok">
        <Slidercoll />
      </div>
    </div>
  );
}

export default Colleagues;
