
import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import "./slidercoll.css";


import { Autoplay, Pagination } from "swiper";
 
export default function Slidercoll() {
  return (
    <>
      <Swiper
        slidesPerView={5}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        grabCursor={true}
        centeredSlides={true}
        loop ={true}
        breakpoints={{

       
          600: {
            
            slidesPerView: 3,
          },
          
          300: {
            
            slidesPerView: 3,
          },
         1040: {
          
            slidesPerView:5
          }
        }}

        
        autoplay={{
          delay: 2000,
          // disableOnInteraction: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper-1"
      >
        
        <SwiperSlide><img className="img-0" src="/images/hayemyes.svg.svg" alt="Հայկական Կայքեր"></img></SwiperSlide>
        <SwiperSlide><img className="img-1" src="/images/brabion.svg" alt="Օնլայն Խանութ Կայք"></img></SwiperSlide>
        <SwiperSlide><img className="img-2" src="/images/logo abio 1.svg" alt="Հիպերմարկետ Վեբ կայքեր"></img></SwiperSlide>
        <SwiperSlide><img className="img-3" src="/images/acba.svg" alt="webkayqer website"></img></SwiperSlide>
        <SwiperSlide><img className="img-4" src="/images/name.svg" alt="website design"></img></SwiperSlide>
        <SwiperSlide><img className="img-1" src="/images/OntimeAuto.png" alt="Մեքենաների-կայքերի-պատրաստում"></img></SwiperSlide>
        <SwiperSlide><img className="img-1" src="/images/sievn.png" alt="Բնակարաների ԿԱՅՔԵՐԻ Պատրաստում"></img></SwiperSlide>
        <SwiperSlide><img className="img-1" src="/images/multi.png" alt="ՎԵԲ ԿԱՅՔ"></img></SwiperSlide>
        <SwiperSlide><img className="img-0" src="/images/imperfos.png" alt="Շինարարական Կայք"></img></SwiperSlide>
        <SwiperSlide><img className="img-3" src="/images/stroimag.png" alt="ՎԵԲ"></img></SwiperSlide>
        <SwiperSlide><img className="img-1" src="/images/ARS-CUSTOM.png" alt="Կայքերի Պատրաստում"></img></SwiperSlide>
      

      </Swiper>
    </>
  );
}

        

