import React from "react";
import { Slid } from "./slid";
import "./slid.css"
import { Link } from "react-router-dom";

import {useTranslation} from "react-i18next"



function Portfolio() {
    const { t } = useTranslation();

    return (
        <div className="portfplio-cont">
            <div className="port_header">
                <h3> {t("portfolio")}</h3>
            </div>
            <div className="portfolio-slider-cont">
                <Slid />
            </div>
            
            <div className="btn btnport">
                 {/* <a href="/portfolio"> <button>{t("portfolio-btn")}</button></a> */}
                 <Link to="/portfolio"> <button>{t("portfolio-btn")}</button></Link>

                 {/* <HashLink to="/portfolio/#port" ><button>{t("portfolio-btn")}</button></HashLink> */}
          </div>
        </div>
    );

}
 

export default Portfolio;