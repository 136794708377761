import React from "react";
import { useTranslation } from "react-i18next";

function Opinion() {
  const { t } = useTranslation();
  return (
    <div className="opinion">
      <div className="op_header">
        <h3> {t("opinion")} </h3>
      </div>
      <div className="op_three">
        <div className="firstpath">
          <img src="/images/marduk1.svg"></img>
          <p className="name">
            {t("opinion1-name")}
          </p>
          <p className="info">
            {t("pinion1-text")}
          </p>
        </div>
        <div className="bar bar2 left">
          <p>
          {t("opinion1-info")}           
          </p>
        </div>
        <div className="op_vector">
          <img src="/images/vector.svg"></img>
        </div>
      </div>
    </div>
  );
}

export default Opinion;
