import React from "react";
import Upper from "../components/Upper/Upper";
import Navbar from "../components/Navbar/Navbar";
import Header from "../components/Header/Header";
import About from "../components/About/About";
import Footer from "../components/Footer/Footer";
import Question from "../components/Questions/Question";
import Design from "../components/Design/Design";
import Animation from "../components/Animation/Animation";
// import Opinion from "../components/Opinion/Opinion";
import Services from "../components/Services/Services";
import Feedback from "../components/Feedback/Feedback";
import Ourteam from "../components/Ourteam/Ourteam";
import Sliderop from "../components/Opinion/sliderop";
import Workstyle from "../components/Workstyle/workstyle";
import Colleagues from "../components/colleagues/colleagues";
import ScrolToTop from "../components/ScrollToTop/ScrollToTop"
// import Portfolio from "../components/Portfolio/Portfolio";
import '../App.css'
import Slid from "../components/Portfolio/slid";
import Portfolio from "../components/Portfolio/portfolio";
import MobileNav from "../components/Navbar/MobileNav";
import { Element, Link, animateScroll as scroll } from 'react-scroll';



function Home() {
  return (
    <div className="root-cont">
      <Upper />
      <Navbar />
      <MobileNav/>
      <Header />
      <Services/>
      <Design />
      <About />
      <Portfolio/>
      <Animation/>
      <Workstyle/>
      {/* <Ourteam/> */}
      <Colleagues/>
      <div>
      <Sliderop/>
      </div>

      <Question />
      <Feedback/>
      <Footer />
      <ScrolToTop/>
    </div>
  );
}

export default Home;
