import React, {useEffect, useState} from "react";
import { MdPhoneInTalk } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const animation = {
  hidden: {
    x: 100,
    y: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};
function Upper() {

  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || "hy"
  );

  useEffect(() => {
    localStorage.setItem("language", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
    
  };

  return (
    <motion.div
      className="upper cont"
      initial="hidden"
      whileInView="visible"
      custom={2}
      variants={animation}
    >
      <div className="title-img">
      <Link to="/home">
        <img src="../images/websoft.svg" className="icon-img" alt="web kayqeri patrastum Վեբ կայքերի պատրաստում"></img>
        </Link>
      </div>
      <div className="cont-info ">
        <div className="phone">
          <MdPhoneInTalk size="25px" className="icona" />
          <a href="tel:43687787" target="_blank">+374 43 687 787</a>
        </div>
        <div className="message">
          <IoIosMail size="25px" className="iconam" />
          <a href="mailto:info@websoft.am" target="_blank">info@websoft.am</a>
        </div>
        <div className="dropdown">
          <img src="../images/blue.svg"></img>
          <select  onChange={(e)=>changeLanguage(e.target.value)}>
            <option value="hy"   >ՀԱՅ</option>
            <option value="en"  >ENG</option>
            <option value="ru" >RUS</option>
          </select>
        </div>
      </div>
    </motion.div>
  );
}

export default Upper;
