import React, {useState} from "react";

import { useTranslation } from "react-i18next";

const animation = {
  hidden: {
    x: -200,
    y: -200,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};
const animation1 = {
  hidden: {
    x: 200,
    y: 200,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};


export default function Telinfo() {
    const { t } = useTranslation();
    const [isActive, setActive] = useState(false);
    const toggleClass = () => {
      setActive(!isActive);
    };
  
    const [visible, setVisible] = useState(false)
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 350){
        setVisible(true)
      } 
      else if (scrolled <= 350){
        setVisible(false)
      }
    };
    
  
    
    window.addEventListener('scroll', toggleVisible);
  return (
    <div>
           <div className="icon-fix" style={{display: visible ? 'flex' : 'none'}}   onClick={toggleClass}>
          <svg className="icon-fix-1" width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.8538 12.625C32.2045 12.625 30.8026 12.0477 29.6481 10.8932C28.4936 9.73872 27.9163 8.33681 27.9163 6.6875C27.9163 5.03819 28.4936 3.63628 29.6481 2.48177C30.8026 1.32726 32.2045 0.75 33.8538 0.75C35.5031 0.75 36.9051 1.32726 38.0596 2.48177C39.2141 3.63628 39.7913 5.03819 39.7913 6.6875C39.7913 8.33681 39.2141 9.73872 38.0596 10.8932C36.9051 12.0477 35.5031 12.625 33.8538 12.625ZM8.12467 36.375L3.57259 40.9271C2.94586 41.5538 2.22808 41.6937 1.41926 41.3467C0.611758 41.001 0.208008 40.3828 0.208008 39.4922V8.66667C0.208008 7.57812 0.595925 6.64594 1.37176 5.8701C2.14627 5.09559 3.0778 4.70833 4.16634 4.70833H24.1559C24.024 5.36806 23.958 6.02778 23.958 6.6875C23.958 7.34722 24.024 8.00694 24.1559 8.66667C24.6177 10.9427 25.7558 12.8308 27.57 14.331C29.3842 15.8326 31.4788 16.5833 33.8538 16.5833C34.9094 16.5833 35.9485 16.4184 36.971 16.0885C37.9936 15.7587 38.9337 15.2639 39.7913 14.6042V32.4167C39.7913 33.5052 39.4041 34.4374 38.6296 35.2132C37.8537 35.9877 36.9215 36.375 35.833 36.375H8.12467Z" fill="#1E4078" />
          </svg>
          <div   className={isActive ? 'icon-fix-cont': 'icon-fix-not'}  >
            <div className="icon-fix-box">
              <img className="icon-fix-img" alt="#icon" src="../images/image 5.svg"></img>
              <a href="t.me/WebSoft_LLC" target="_blank"> <p className="icon-fix-img-info">Telegram</p></a>
            </div>
            <div className="icon-fix-box icon-fix-box-1">
              <img className="icon-fix-img " alt="#icon" src="../images/image 6.svg"></img>
             <a href="tel:+37443687787" target="_blank"> <p className="icon-fix-img-info">Phone</p></a>
            </div>
            <div className="icon-fix-box">
              <img className="icon-fix-img" alt="#icon" src="../images/image 7.svg"></img>
              <a href="https://wa.me/37443687787?text=WebSoftllc%20Software%20Engineer%20Company" target="_blank"><p className="icon-fix-img-info">WhatsApp</p> </a>
            </div>
            <div className="icon-fix-box">
              <img className="icon-fix-img" alt="WebSoft" src="../images/mes.png"></img>
              <a href="https://www.facebook.com/WebSoftLLC" target="_blank"> <p className="icon-fix-img-info">Messenger</p> </a>
            </div>
          </div>
        </div>
      
    </div>
  )
}
