import React from "react";
import {useTranslation} from "react-i18next"
// import { Link } from "react-router-dom";
import { MdPhoneInTalk } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { CiLocationOn } from "react-icons/ci";
import { FiInstagram } from "react-icons/fi";
import { BsFacebook } from "react-icons/bs";
import { AiFillLinkedin } from "react-icons/ai";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="cont">
      <div className="parts">
        <div className="pages">
          <ul>
            <li><Link to="/home/#services"> {t("service")} </Link></li>
            <li> <Link to="/portfolio"> {t("portfolio")} </Link></li>
            <li> <Link to="/home/#about"> {t("about")} </Link></li>
            <li> <Link to="/home/#contact"> {t("contact")} </Link></li>
          </ul>
        </div>
        <div className="contact">
          <div className="icon">
            <MdPhoneInTalk />
            <a href="tel:+37443687787" target="_blank"> +374 43 687 787</a>
          </div>
          <div className="icon">
            <IoIosMail />
            <a href={`mailto:info@websoft.am`} target="_blank"> info@websoft.am</a>
          </div>
          <div className="icon">
            <CiLocationOn />
            <p> {t("address")}</p>
          </div>
        </div>

        <div className="follow">
          <h3>{t("followus")} </h3>
          <a href="https://www.linkedin.com/company/websoft-company/" target="_blank">
            <AiFillLinkedin />
          </a>
          <a href="https://www.facebook.com/WebSoftLLC" target="_blank">
            <BsFacebook />
          </a>
          <a href="https://www.instagram.com/websoftllc/" target="_blank">
            <FiInstagram />
          </a>
        </div>
      </div>
      <div className="contacts">
        <div className="icon12">
          <div className="icon1">
            <MdPhoneInTalk color="white" />
            <a href="tel:+37443687787" target="_blank"> +374 43 687 787</a>
          </div>
          <div className="icon2">
            <IoIosMail color="white" />
         
            <a href={`mailto:info@websoft.am`} target="_blank"> info@websoft.am</a>
          </div>
        </div>
        <div className="icon3">
          
          <p> <CiLocationOn color="white" /> ք.Երևան (ԽՈՐԵՆԱՑԻ ԲԻԶՆԵՍ ՊԼԱԶԱ)</p>
          <div className="flw">
            <a href="https://www.linkedin.com/company/websoft-company/" target="_blank">
              <AiFillLinkedin />
            </a>
            <a href="https://www.facebook.com/WebSoftLLC" target="_blank">
              <BsFacebook />
            </a>
            <a href="https://www.instagram.com/websoftllc/" target="_blank">
              <FiInstagram />
            </a>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <p className="copyright">
          Copyright © 2019-2024, WEBSOFT LLC. All rights reserved.
        </p>
      </div>
      </div>
    </footer>
  );
}

export default Footer;

{
  /* <a href="" target="_blank"><FiTwitter/></a> */
}
